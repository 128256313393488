.h6 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 21px;
  line-height: 24px;
}

.h5 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 25px;
  line-height: 32px;
}

.h4 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 33px;
  line-height: 40px;
}

.h1 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 57px;
  line-height: 64px;
}

body,
* {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}

.body-medium {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 24px;
}

.body-small {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  line-height: 24px;
  font-size: 15px;
  /*line-height: 1.25rem;  20px */
}

.sub-heading {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
}

.caption-medium {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
}

.caption-small {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
}
