@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --neutral20: #f8f9fa;
  --neutral30: #ebedf0;
  --neutral40: #cdd3d8;
  --blueRaya50: #07539a;
  --orangeRaya50: #f07126;
}

input,
input:before,
input:after {
  -webkit-user-select: initial !important;
  -khtml-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  user-select: initial !important;
}

textarea,
textarea:before,
textarea:after {
  -webkit-user-select: initial !important;
  -khtml-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  user-select: initial !important;
}

.constraint-width {
  max-width: 640px;
  min-width: 200px;
}

@media only screen and (max-width: 640px) {
  .constraint-width {
    width: auto;
  }
}

#content {
  padding: 0px 16px 16px 16px;
}

.gradient-border {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

/* CSS untuk mencegah overscroll */
.no-scroll {
  overflow: hidden !important;
}

.no-overscroll {
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 1px; /* Border thickness */
  background: linear-gradient(94.7deg, rgba(255, 255, 255, 0.38) 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.custom-button {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: var(--orangeRaya50);
  color: white;
  border-radius: 8px;
  height: 48px;
}

.custom-image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}

.lato {
  font-family: 'Lato', sans-serif;
}

#deskripsi-banner {
  font-family: 'Lato', sans-serif;
}

.ant-message {
  bottom: 20px;
  top: initial;
}

#deskripsi-banner table {
  border-radius: 3px;
  display: block;
  overflow: auto;
  white-space: nowrap;
}

#deskripsi-banner table td {
  border: 1px solid;
}

#onboarding {
  font-family: 'Lato', sans-serif;
}

#dashboard {
  font-family: 'Lato', sans-serif;
}

.ant-drawer-content {
  font-family: 'Lato', sans-serif;
  border-radius: 16px 16px 0px 0px;
}

.navigation-menu {
  background: #ffffff;
  box-shadow: 0px -2px 8px rgba(56, 25, 7, 0.1);
  border-radius: 24px 24px 0px 0px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  width: 100%;
  max-width: 640px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 20;
}

.circle-qris {
  background: white;
  box-shadow: 0px -2px 8px rgba(56, 25, 7, 0.1);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%);
  z-index: 20;
}

.button-fixed-bottom {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 12px 12px 0px 0px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  width: 100%;
  max-width: 640px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 20;
  padding: 1rem; /* 16px */
}

.qris-activation-button {
  background: #ffffff;
  text-align: center;
  vertical-align: middle;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  width: 100%;
  max-width: 640px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 20;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f07126;
  border-color: #f07126;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f07126 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

#checkbox-saku .ant-checkbox-inner {
  border-width: 1.5px;
  --tw-border-opacity: 1;
}
/* Custom Tooltip Introjs */
.customTooltip {
  min-width: 258px !important;
}

.customTooltip .introjs-tooltip-title {
  color: #03213e;
  font-size: 12px !important;
  padding-top: 6px !important;
}

.customTooltip .introjs-tooltiptext {
  color: #03213e;
  padding: 16px !important;
  margin: 0 !important;
  font-size: 12px !important;
}

.customTooltip .introjs-tooltip-header .introjs-skipbutton {
  color: #03213e;
  font-size: 20px !important;
}

.customTooltip .introjs-bullets ul li a {
  background: #ebedf0 !important;
}

.customTooltip .introjs-bullets ul li a.active {
  background: #07539a !important;
}

.customTooltip .introjs-tooltipbuttons .introjs-button {
  background-color: #f07126;
  font-size: 12px;
  font-weight: bold;
  text-shadow: none;
  color: #ffffff;
  border-radius: 6px;
  height: 14px;
  padding: 2px 20px 8px 20px;
  border: none;
}

.customTooltip .introjs-prevbutton {
  display: none !important;
}

/* Custom Antd */
.ant-tabs-top .ant-tabs-nav {
  text-align: center;
  justify-content: space-between;
}

.ant-tabs-top .ant-tabs-tab {
  flex-grow: 1;
  text-align: center;
  display: flow-root;
}

.ant-tabs-top .ant-tabs-tab:hover {
  text-decoration: none;
  color: #f07126;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: -webkit-fill-available;
}

.ant-tabs-content.ant-tabs-content-top {
  height: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f07126;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #f07126;
}

.custom-dashed-border {
  border-top-style: dashed;
  border-image: repeating-linear-gradient(
      to right,
      transparent 0px,
      transparent 4px,
      #ebedf0 4px,
      #ebedf0 16px
    )
    1;
}

.custom-dashed-border-slim {
  border-top: 1px dashed transparent;
  border-image: repeating-linear-gradient(
      to right,
      transparent 0px,
      transparent 2px,
      #ebedf0 2px,
      #ebedf0 10px
    )
    4;
}

.custom-dropshadow-top {
  border-radius: 12px 12px 0px 0px;
  background: #fff;
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
}

/* .customTooltip .introjs-button:hover {
  background-color: #F07126;
  font-size: 12px;
  font-weight: bold;
  text-shadow: none;
  color: #ffffff;
  border-radius: 6px;
  height: 14px;
  padding: 2px 8px 8px 8px;
} */

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.modal-loading .ant-modal-content {
  border-radius: 10px;
}

.modal-loading .ant-modal-content .ant-modal-body {
  padding-top: 16px;
  padding-right: 12px;
  padding-bottom: 16px;
  padding-left: 12px;
}

.custom-overflow-x-auto {
  overflow-x: auto;
  padding-bottom: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.animate-fade-in {
  animation: fade-in 0.4s ease-in-out forwards;
}

@keyframes fade-in-up {
  0% {
    opacity: 1;
    transform: translateY(-8rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 0.5s ease-in-out forwards;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(8rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-down {
  animation: fade-in-down 0.5s ease-in-out forwards;
}

@keyframes fade-in-down-small {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-down-small {
  animation: fade-in-down-small 0.5s ease-in-out forwards;
}

@keyframes fade-in-right {
  0% {
    opacity: 1;
    transform: translateX(-4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in-right {
  animation: fade-in-right 0.5s ease-in-out forwards;
}

@keyframes rotate-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

.rotate-45 {
  transition: transform 0.3s ease-in-out;
}

.hover\:rotate-45:hover {
  animation: rotate-icon 0.3s ease-in-out;
}

img {
  pointer-events: none;
}

.introjs-tooltipReferenceLayer {
  visibility: visible !important;
}
