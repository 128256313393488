.elevation-01 {
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.08);
}

.elevation-03 {
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.02), 0px 4px 8px rgba(96, 97, 112, 0.12);
}

.elevation-06 {
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24);
  box-shadow: 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
}

.button-shadow {
  box-shadow: 0px 4px 10px -5px rgba(0, 0, 0, 0.25);
}
