.skeleton {
  --tw-bg-opacity: 1;
  background-color: rgb(235 237 240 / var(--tw-bg-opacity));
  animation: pulse 1.2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

.skeleton.skeleton-title {
  width: 8.5rem /* 136px */;
  height: 0.75rem /* 12px */;
  border-radius: 9999px;
}

.skeleton.skeleton-saku-information {
  width: 100%;
  height: 4rem /* 64px */;
  border-radius: 0.375rem /* 6px */;
}

.skeleton.skeleton-menu {
  width: 4rem;
  height: 4rem;
  border-radius: 20px;
}

.skeleton.skeleton-menu-title {
  width: 4rem;
  height: 0.75rem;
  border-radius: 9999px;
}

.skeleton.skeleton-wallet.skeleton-profile {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
}
.skeleton.skeleton-wallet.skeleton-title {
  width: 11.25rem;
  height: 0.75rem /* 12px */;
  border-radius: 9999px;
}