@keyframes fadeInUp {
  from {
    opacity: 1;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(40);
  }
}

@keyframes growDown {
  0% {
    transform: translate(0px, -200px);
  }
  25% {
    transform: translate(0px, -150px);
  }
  50% {
    transform: translate(0px, -100px);
  }
  75% {
    transform: translate(0px, -50px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes growUp {
  0% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: translate(0px, -80px);
  }
  70% {
    transform: translate(0px, -120px);
  }
  100% {
    transform: translate(0px, -200px);
  }
}

.list-user-down {
  animation: growDown 300ms ease-in-out;
}

.list-user-up {
  animation: growUp 300ms ease-in-out;
}

.animate-fade-in-up-two {
  animation: fadeInUp 2s ease-out;
}

.animate-fade-in-up-one {
  animation: fadeInUp 1s ease-out;
}

.animate-fade-in-down-two {
  animation: fadeInDown 2s ease-out;
}

.animate-fade-in-down-one {
  animation: fadeInDown 1s ease-out;
}
